<template>
  <div v-if="item">
    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)">
<!--      <router-link :to="{path:resolvePath(item.path)}">-->
<!--      </router-link>-->
        <el-menu-item v-if="item.meta.isHide==false" :index="resolvePath(item.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <span slot="title">
            <i :class="item.meta.icon||(item.meta&&item.meta.icon)"></i>
            {{ item.meta.title }}
            <template  v-if="item.path==$route.fullPath">
            <div class="active-div"></div>
            </template>
          </span>
        </el-menu-item>
    </template>
    <template v-else>
      <el-submenu v-if="item.meta.isHide==false"  ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body style="padding: 0">
        <span slot="title"><i v-if="item.meta" :class="item.meta && item.meta.icon"></i>{{ item.meta.title }}</span>
        <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :is-nest="true"
          :item="child"
          :base-path="resolvePath(child.path)"
          class="nest-menu"
        />
      </el-submenu>
    </template>
  </div>
</template>

<script>
import path from 'path'
import SidebarItem from "./SidebarItem";

export default {
  name: 'SidebarItem',
  components: {SidebarItem},
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null
    return {}
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      if (!children) {
        children = [];
        return false
      }
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          this.onlyOneChild = item
          return true
        }
      })
      if (showingChildren.length === 1) {
        return true
      }
      if (showingChildren.length === 0) {
        this.onlyOneChild = {...parent, path: '', noShowingChildren: true}
        return true
      }
      return false
    },
    //判断是否为json串
    isJson(obj) {
      var isjson = typeof (obj) == "object" && Object.prototype.toString.call(obj).toLowerCase() == "[object object]" && !obj.length;
      return isjson;
    },
    resolvePath(routePath, routeQuery) {
      if ((routePath)) {
        return routePath
      }
      if (routeQuery) {
        let query = this.isJson() == true ? JSON.parse(routeQuery) : routeQuery;
        return {path: path.resolve(this.basePath, routePath), query: query}
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-submenu .el-menu-item{
  padding: 0 !important;
  //margin: 0 12px!important;
  border-radius: 10px!important;
}
</style>
